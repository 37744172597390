import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs", config);
};

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/" + id);
};

const photo = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/photo/" + id, {
        responseType: "arraybuffer",
    });
};

const uploadPhoto = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/student/programs/photo/" + id, formData);
};

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id, formData);
};

const updateIdentity = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/identity", formData);
};

const updateForeign = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/foreign", formData);
};

const updateAddress = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/address", formData);
};

const updateFamily = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/family", formData);
};

const updateMilitary = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/military", formData);
};

const updateHealth = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/health", formData);
};

const updateContact = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/contact", formData);
};

const updateOtherUniversity = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/" + id + "/other-university", formData);
};

const documents = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/" + id + "/ebys/documents");
};

const document = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/ebys/document/" + id, {responseType: "arraybuffer"});
};

const search = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/search", config);
};

const getMedicineGroups = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/medicine/groups/", config);
};

const updateMedicineGroups = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/medicine/groups", formData);
};

const updateClasses = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/update-classes", config);
};

const getAdvisorStudents = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/advisor/students", config);
};

const studentSlots = async (studenProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/" + studenProgramId + "/slots");
};

const updateMissingDocument = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let updateData = {
        missing_documents: formData,
    };
    return Api.put(
        "/student/programs/" + id + "/missing-documents",
        updateData
    );
};

const missingDocumentsReport = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/missing-documents-excel-export", {
        ...formData,
        responseType: "arraybuffer",
    });
};

const getYokRegistrationStatusReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/lecturer/reports/yok-registration-status-report", config);
};

const exportExcel = async (config, responseType = "arraybuffer") => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = responseType;
    return Api.get("/student/programs/excel-export", config);
};

const getGraduationRequirement = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/graduation-requirement-report", config);
};

const excelGraduationRequirement = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(
        "/student/programs/graduation-requirement-report/excel-export",
        config
    );
};

const excelExportClassSuccess = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/class-success/excel-export-job", config);
};

const excelGraduationSuccess = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(
        "/student/programs/graduation-success/excel-export-job",
        config
    );
};

const sendTranscript = (studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/student/programs/yoksis-send-transcript/" + studentProgramId
    );
};

const addOrUpdateTranscript = (studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put(
        "/student/programs/yoksis-add-or-update-student/" + studentProgramId
    );
};

const getStudentCourses = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/student-courses-with-attendance", config);
};

const delTranscript = (studentProgramId,unitId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/student/programs/yoksis-delete-student/" + studentProgramId+'/'+unitId
    );
};

const bulkGraduate = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/student/programs/bulk-graduate", formData);
};

const approveKvkk = () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/program/extra/infos/approve-kvkk");
};

const del = (student_ID) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/student/programs/" + student_ID);
};

const gpaCalculate = async (studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/student/programs/" + studentProgramId + "/calculate/gpa");
};

const updateGraduateRequirementInfo = (studentProgramId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/student/programs/"+studentProgramId+"/graduation-requirement-information", formData);
};

export default {
    addOrUpdateTranscript,
    bulkGraduate,
    delTranscript,
    document,
    documents,
    excelExportClassSuccess,
    excelGraduationRequirement,
    excelGraduationSuccess,
    exportExcel,
    get,
    getAdvisorStudents,
    getAll,
    getGraduationRequirement,
    getMedicineGroups,
    getStudentCourses,
    missingDocumentsReport,
    getYokRegistrationStatusReport,
    photo,
    search,
    sendTranscript,
    studentSlots,
    update,
    updateAddress,
    updateClasses,
    updateContact,
    updateFamily,
    updateForeign,
    updateHealth,
    updateIdentity,
    updateMedicineGroups,
    updateMilitary,
    updateMissingDocument,
    updateOtherUniversity,
    uploadPhoto,
    approveKvkk,
    del,
    gpaCalculate,
    updateGraduateRequirementInfo
};
